<template>
	<div>
		<div id="div1">

		</div>
	</div>
</template>

<script>
	import E from 'wangeditor'
	export default{
		data(){
			return {

			}
		},
		mounted(){
			this.init()
		},
		methods:{
			init(){

				let _this = this

				const editor = new E('#div1')

        console.log(editor)
        window.$editor = editor

				editor.create()

				editor.config.customUploadImg = function (resultFiles, insertImgFn) {

					for(let i in resultFiles){

						var formdata = new FormData();
						formdata.append("file",resultFiles[i]);
						formdata.append("token",sessionStorage.getItem('token'));
            formdata.append('type', '商品详情')
            formdata.append('cid', sessionStorage.getItem('cid'))
            formdata.append('uplode_person', '管理员')

						_this.axios.post(_this.$domain+'/scs/index/addGoodImg',formdata).then(res => {
						   let result = res.data.data.img_url
							console.log(result)
						     insertImgFn(result)

						});
					}

				}



				 // editor.txt.html('<p>用 JS 设置的内容</p>') // 重新设置编辑器内容

			},
		}

	}
</script>

<style>
</style>
