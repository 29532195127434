<template>
    <div>
        <div>
            <div>
                <el-button type="primary" @click="addBtn" size="mini">添加商品</el-button>
            </div>
        </div>
        <br>
        <div class="searchForm">
            <div>
                商品名称： <el-input size="small" v-model="search_form.good_name" placeholder="商品名称"  style="width:150px;"></el-input>
            </div>
            <div>
                商品单价： <el-input size="small" v-model="search_form.good_price" placeholder="请输入商品单价"  style="width:150px;"></el-input>
            </div>

            <div>
                商品分类：
                    <el-select v-model="search_form.good_type_id" placeholder="请选择" size="small">
                        <el-option
                          v-for="item in goodType"
                          :key="item.id"
                          :label="item.good_class_name"
                          :value="item.id">
                        </el-option>
                      </el-select>
            </div>
            <div>
                审核状态：
                    <el-select v-model="search_form.is_checked" placeholder="请选择" size="small">
                        <el-option
                          v-for="item in checkedType"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
                      </el-select>
            </div>


            <div>
                商品状态： <el-radio-group v-model="search_form.good_status">
                                <el-radio label="-1">不限</el-radio>
                                <el-radio label="0">下架</el-radio>
                                <el-radio label="1">上架</el-radio>
                            </el-radio-group>
            </div>
            <div>
                是否团购： <el-radio-group v-model="search_form.is_alse">
                                <el-radio label="-1">不限</el-radio>
                                <el-radio label="0">否</el-radio>
                                <el-radio label="1">是</el-radio>
                            </el-radio-group>
            </div>
            <div>
                添加时间：  <el-date-picker
                    size="small"
                    v-model="search_form.time"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
            </el-date-picker>
            </div>
            <div>
                <el-button type="primary" size="small" @click="search">检索</el-button>
                <el-button size="small" @click="clearSearch">重置/检索</el-button>
            </div>
        </div>
        <br>
        <el-table  :data="goodsList"   border  style="width: 100%">
           <!-- <el-table-column   align="center"    prop="gid"    label="商品ID" width="80"></el-table-column> -->
            <el-table-column   align="center"    prop="good_name"    label="商品名称"></el-table-column>
            <el-table-column  align="center"     label="商品单价">
                <template slot-scope="scope">
                    <span>{{scope.row.good_price}}元 /{{scope.row.unit}}</span>
                </template>
            </el-table-column>
            <el-table-column    align="center" width="100"    prop="banner"  label="商品图片">
                <template slot-scope="scope">
                    <div v-if="scope.row.banner1">
						<!-- <bigImg 
						:furl="getBanner(JSON.parse(scope.row.banner1))" 
						width="50px" height="50px"></bigImg> -->
						<el-image 
						    v-if="isShowListImg"
						    style="width: 50px; height: 50px"
						    :src="getBanner(JSON.parse(scope.row.banner1))" 
						    :preview-src-list="[getBanner(JSON.parse(scope.row.banner1))]">
						</el-image>
                    </div>
                </template>
            </el-table-column>
             <el-table-column   align="center"   prop="inventory"   label="库存"></el-table-column>
            <el-table-column  align="center"  label="商品分类">
               <template slot-scope="scope">
                   <div>
                       {{returnGoodType(scope.row.good_type_id)}}
                   </div>
               </template>
            </el-table-column>
             <el-table-column   align="center"    prop="sort"    label="商品排序(越小越靠前)"></el-table-column>
            <el-table-column label="添加时间" width="180"  align="center" >
                      <template slot-scope="scope">
                         <div>
						    {{scope.row.add_time | formatTime}}
                         </div>
                      </template>
            </el-table-column>
			<el-table-column label="商品状态" width="180"  align="center" >
			          <template slot-scope="scope">
			             <div>
						    <!-- {{scope.row.good_status==0?'下架':'上架'}} -->
							<div>
							  <el-tag v-if="scope.row.good_status==0" type="danger" effect="dark">下架</el-tag>
							  <el-tag v-else  effect="dark">上架</el-tag>
							</div>
			             </div>
			          </template>
			</el-table-column>
            <!-- <el-table-column label="更新时间" width="180"  align="center" >
                      <template slot-scope="scope">
                         <div>
						    {{scope.row.update_time | formatTime}}
                         </div>
                      </template>
            </el-table-column> -->
            <el-table-column label="团购状态"  align="center" >
                      <template slot-scope="scope">
                         <div>
                           <el-tag v-if="scope.row.is_alse==0" effect="dark">正常商品</el-tag>
                           <el-tag v-else type="danger" effect="dark">团购商品</el-tag>
                         </div>
                      </template>
            </el-table-column>
            <el-table-column   align="center"   label="审核状态">
                <template slot-scope="scope">
                   <div>
					   <span @click="showCause(scope.row)" 
					   v-if="scope.row.is_checked==0" style="cursor: pointer;">不通过(查看原因)</span>
					   <span v-else>通过</span>
                   </div>
                </template>
            </el-table-column>
            <!-- <el-table-column   align="center"   prop="remark"   label="备注"></el-table-column> -->
            <el-table-column  label="操作"  fixed="right"  align="center">
                <template slot-scope="scope">
                    <el-button @click="update(scope.row)" type="text" size="small">更新</el-button>
                    <el-button type="text" size="small" @click="del(scope)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="page">
            <el-pagination
                    background
                    @size-change="pageChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="page_totle">
            </el-pagination>
        </div>
        <el-dialog
                :title="title"
                :visible.sync="show_editor_good"
                width="730px"
        >
            <el-form  :model="good_info" label-width="120px">
                <el-form-item label="商品名称" :required="true">
                    <el-input v-model="good_info.good_name" style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="商品单价" :required="true">
                    <el-input type="number" v-model="good_info.good_price" size="small" min="0" style="width: 100px;"></el-input> 元
                      <span style="color:red">*团购商品设为0将不能购买</span>
                </el-form-item>
                <el-form-item label="商品图片" :required="true">
                    <selecrImg @getImg="getImg"></selecrImg>
                     <div style="display: flex;flex-wrap: wrap;" v-if="updateLunbo">
                        <div v-for="(el,i) in good_info.banner" :key="i"  style="margin-top: 10px;margin-right: 10px;" class="close_img_box">
                            <img style="vertical-align: top;" width="150px" height="150px" 
							:src="goodsImgUrl+el" alt="">
                            <div class="close_img" @click="delSonImg(i)" style="right: 0;">
                              <img src="@/assets/img/close_white.svg" style="right: 1px;top: 2px;cursor: pointer;">
                            </div>
                        </div>
                     </div>
                </el-form-item>
                <el-form-item label="商品分类">
                   <el-select v-model="good_info.good_type_id" placeholder="请选择" size="small">
                       <el-option
                         v-for="item in goodType"
                         :key="item.id"
                         :label="item.good_class_name"
                         :value="item.id">
                       </el-option>
                     </el-select>
                 <span style="color:red">*无数据请在商品分类功能添加</span>
                </el-form-item>
                <el-form-item label="商品状态" :required="true">
                    <el-radio-group v-model="good_info.good_status">
                        <el-radio label="0">下架</el-radio>
                        <el-radio label="1">上架</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="商品库存" :required="true">
                    <el-input-number v-model="good_info.inventory" size="small"></el-input-number>
                </el-form-item>
                <el-form-item label="商品单位" :required="true">
                    <el-input v-model="good_info.unit" style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="商品排序" :required="true">
                    <el-input v-model="good_info.sort" style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="活动状态" :required="true">
                    <el-radio-group v-model="good_info.is_alse">
                        <el-radio label="0">正常商品</el-radio>
                        <el-radio label="1">团购商品</el-radio>
                    </el-radio-group>
                </el-form-item>

                <!-- <el-form-item label="商品规格">
                        <addSpec @getSpec="getSpec" :dadSpec="good_info.good_spec"></addSpec>
                </el-form-item> -->

                <el-form-item label="商品备注">
                      <el-input type="textarea" v-model="good_info.remark" style="width: 500px"></el-input>
                </el-form-item>
                <!-- <el-form-item label="商品简介">
                   <wangedit></wangedit>
                 </el-form-item> -->
            </el-form>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="show_editor_good = false">取 消</el-button>
                    <el-button type="primary" @click="addGood">确 定</el-button>
                </span>
        </el-dialog>

    </div>
</template>
<script>

    import selecrImg from '@/components/selectImg'
    import addSpec from '@/components/addSpec'
    import wangedit from '@/components/wangEditor.vue'
    export default {
        components:{
            selecrImg,
            addSpec,
            wangedit
        },

        data(){
            return {
              checkedType:[
                {name:'不通过',value:'0'},
                {name:'通过',value:'1'},
                {name:'审核中',value:'2'},
              ],
                editorOption: {},
                title:'添加商品',
                show_editor_good:false,
                pageSize:10,
                page:1,
                page_totle:0,
                search_form:{
                    is_checked:undefined,
                    good_name:'',
                    good_price:'',
                    good_type_id:'',
                    good_status:'-1',
                    time:[],
                    start_time:'',
                    end_time:'',
                    is_alse:'-1'
                },
                goodsList:[],
                goodType:[],
                good_info:{
                    gid:null,
                    banner:[],
                    good_type_id:'',
                    good_name:'',
                    good_price:'',
                    good_status:'1',
                    remark:'',
                    content: '',
                    is_alse:'0',
                    unit:"",
                    inventory:100, //库存
                    sort:0
                },
                updateLunbo:true,
				isShowListImg:true,
            }
        },
        mounted(){
            document.title = '商品列表'
            this.getGoodClass()
            this.getGoodList()
        },
        methods:{
			showCause(row){
				this.$notify({
				  title: '拒绝原因',
				  message: row.cause
				});
			},
            returnGoodType(id){
               var str = ''
               for(let i in this.goodType){
                 if(this.goodType[i].id==id){
                   str = this.goodType[i].good_class_name
                 }
               }
               return str
            },
            del(el){
                let _this=this
                if(confirm('确定删除')){
                    var index = el.$index
                    var gid=el.row.gid
                    this.$post('/pc/goods/delGood',{gid:gid},function (res) {
                        let result = res.data
                        if(result.code==200){
                            _this.goodsList.splice(index,1)
                            _this.page_totle--
                            _this.$message.success(result.msg)
                        }
                    })
                }
            },
            getBanner(arr){
                if(arr.length!= 0){
                    return this.goodsImgUrl + arr[0]
                }else{
                    return ''
                }
            },
            getSpec(spec){
                // if(spec){
                //     this.good_info.good_spec = spec
                // }
            },
            delSonImg(i){
                this.updateLunbo = false
                this.$nextTick(() => {
                    this.updateLunbo = true
                     this.good_info.banner.splice(i,1)
                })
            },
            getImg(data){
             this.updateLunbo = false
                this.$nextTick(() => {
                  this.updateLunbo = true
                   this.good_info.banner.push(data.url)
                })
            },
            addGood(){
                let _this = this
                if(!this.good_info.good_name) return this.$message.error('请输入商品名称')
                if(!this.good_info.unit) return this.$message.error('请输入商品单位')
                // _this.good_info.content = window.$editor.txt.html();
                _this.good_info.banner1 = JSON.stringify(_this.good_info.banner)
                this.$post('/pc/goods/addGood',_this.good_info,function (res) {
                         let result = res.data
                     if(result.code==200){
                            _this.getGoodList()
                            _this.show_editor_good = false
                     }
                })
            },
            getGoodList(){
                let _this=this
                _this.search_form.page=_this.page
                _this.search_form.pageSize=_this.pageSize
                

                this.$post('/pc/goods/getGood',_this.search_form,function (res) {
                     let   result = res.data
                    if(result.code== 200){
						_this.isShowListImg = false
						_this.$nextTick(()=>{
							_this.goodsList=result.data.result
							_this.page_totle=result.data.count
							_this.isShowListImg = true
						})
                    }
                })
            },
            pageChange(pageSize){
                this.page=1
                this.pageSize=pageSize
                this.getGoodList()
            },
            handleCurrentChange(page){
                this.page=page
                this.getGoodList()
            },
            getGoodClass(){
                let _this=this
                this.$post('/pc/goods/getGoodsClass',{},function (res) {
                    let result = res.data
                    if(result.code==200 && result.data){
                        _this.goodType = result.data
                    }
                })
            },

            clearSearch(){
                    this.search_form  = {
                            is_checked:undefined,
                            good_name:'',
                            good_price:'',
                            good_type_id:'',
                            good_status:'-1',
                            time:[],
                            start_time:'',
                            end_time:'',
                            is_alse:'-1'
                    }
                    this.page=1
                    this.pageSize=20
                    this.getGoodList()
            },
            search(){
                    let _this = this
                    if(this.search_form.time.length){
                        this.search_form.start_time = new Date(this.search_form.time[0]).getTime()/1000
                        this.search_form.end_time = new Date(this.search_form.time[1]).getTime()/1000
                    }else{
                        this.search_form.start_time = ''
                        this.search_form.end_time = ''
                    }
                    _this.getGoodList()
            },
            addBtn(){
                this.show_editor_good=true

                this.title='添加商品'
                this.good_info = {
                    banner:[],
                    // good_spec:[],
                    gid:null,
                    good_price:'',
                    good_type_id:'',
                    good_status:'1',
                    remark:'',
                    content:'',
                    is_alse:'0',
                    good_name:'',
                    unit:'',
                    inventory:100,
                    sort:0
                }
                // window.$editor.txt.html('')
            },
            update(row){
                this.title='更新商品'
                this.show_editor_good=true
                this.good_info=row
                this.good_info.banner = JSON.parse(row.banner1) || []
				
				

                // if(row.content[0]){
                //   setTimeout(()=>{
                //       window.$editor.txt.html(row.content)
                //   },100)

                // }

                // this.good_info.good_spec = JSON.parse(row.good_spec1) || []

            },
        }
    }
</script>
