<template>
    <div>
        <el-button size="small" @click="addSpec">新增规格</el-button> <span style="color:red">*如果该商品不需要添加规格就不添加了(规格就是商品的颜色 尺码等)</span>
        <div v-for="el,index in spec">
           {{index+1}} <el-input  v-model="el.spec_title" placeholder="请输入规格标题" style="width: 150px" size="small"></el-input>
            <el-button @click="addSpecSon(el,index)" type="primary"  size="small">添加规格子项</el-button>
            <el-button type="danger"  size="small"  @click="delSpec(index)" >删除</el-button>
            <div v-for="item,i in el.spec_item" style="position: relative;left: 40px">
               <div style="display: flex" id="son_spec" @click="selectIndex(i)">
                   ({{i+1}})
                    <img style="width: 50px;height: 50px;margin: 0 10px;" :src="item.spec_item_imgUrl" alt="">
                    <el-input  v-model="item.spec_item_title" placeholder="请输入子项标题" size="small" style="width: 200px"></el-input>
                    <div style="display: flex;margin-top: 3px;">
                      <selectImg @getImg="getImg"></selectImg>
                      <el-button type="danger"  size="small" @click="delSonSpec(i,index)">删除</el-button>
                    </div>
                </div>
                价格 <el-input-number v-model="item.spec_item_price"  size="small" ></el-input-number> 元
            </div>
        </div>
        <!-- <div v-if="spec.length!=0" style="float:right">
            <span style="color:red">*填写完规格后一定要点击确定保存规格</span>
            <el-button size="small" type="primary" @click="sendSpec">确定保存规格</el-button>
        </div> -->
    </div>
</template>
<script>
    import selectImg from '@/components/selectImg'
    export default {
        props:['dadSpec'],
        components:{
            selectImg
        },
        data(){
            return {
                son_index:'',
                index:'',
                spec:[

                ],
            }
        },
        watch:{
            dadSpec:{
                handler:function(val){
                    if(val){
                        this.spec = val
                    }else{
                        this.spec = []
                    }
                    this.$emit('getSpec',this.spec)
                },
                deep:true
            },
        },
        mounted(){
            if(this.dadSpec){
                this.spec = this.dadSpec
            }else{
                this.spec = []
            }
        },
        methods:{
            sendSpec(){
                this.$message.success('保存成功')
                this.$emit('getSpec',this.spec)
            },
            getImg(data){
                if(this.spec[this.index].spec_item[this.son_index]){
                    this.spec[this.index].spec_item[this.son_index].spec_item_imgUrl = data.url
                }else{
                   this.$message.error('请按照顺序添加')
                    this.spec = []
                }
            },
            addSpec(){
                this.spec.push({spec_title:'',spec_item:[]})
            },
            addSpecSon(el,i){
                this.index=i
                el.spec_item.push({
                    spec_item_title:'',
                    spec_item_price:'',
                    spec_item_imgUrl:''
                })
            },
            selectIndex(i){
                this.son_index = i
            },
            delSpec(i){
                this.spec.splice(i,1)
            },
            delSonSpec(i,index){
                this.spec[index].spec_item.splice(i,1)
            },
        },
    }
</script>
<style>
    #son_spec .el-button{
        height: 36px;
       margin-left: 5px;
    }
</style>
